import { useState, useEffect } from 'react';
import { AbortController as AbortControllerPonyfill } from 'abortcontroller-polyfill/dist/cjs-ponyfill';

if (!window.AbortController) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  window.AbortController = AbortControllerPonyfill;
}

type FetchOptions = RequestInit & {
  url: string;
};

type ServerError = {
  status: number;
  detail: string;
  title: string;
};

type Fetch<T> = {
  response: T | null;
  error: Error | null;
  loading: boolean;
};
export function useFetch<T>(fetchOptions: FetchOptions): Fetch<T> {
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const doFetch = async () => {
      setLoading(true);
      try {
        const { url, ...options } = fetchOptions;
        const res = await fetch(url, options);
        const json: T = (await res.json()) as T;

        if (!res.ok) {
          const serverError = json as unknown as ServerError;
          setError(new Error(serverError.detail));
        }

        if (!signal.aborted) {
          setResponse(json);
        }
      } catch (e) {
        if (!signal.aborted && e instanceof Error) {
          setError(e);
        }
      } finally {
        if (!signal.aborted) {
          setLoading(false);
        }
      }
    };

    doFetch().finally(() => {});

    return () => {
      abortController.abort();
    };
  }, [fetchOptions]);

  return { response, error, loading };
}
